<template>
  <a-card :bordered="false">
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <a-tab-pane tab="商品入库明细表" key="1">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="入库单号">
                  <a-input v-model.trim="queryInParam['purchase_num']" placeholder="根据入库单号进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="入库时间">
                  <a-range-picker @change="(a, b) => onDateChange('in', a, b)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择货名"
                    style="width: 100%; z-index: 2;"
                    :value="goodInfo"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getGoodInfo"
                    @change="changeGoodInfo"
                    mode="multiple"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in goodOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="供应商" prop="supplier_id">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    ref="supplier"
                    placeholder="选择供应商"
                    v-model="queryInParam['supplier_ids']"
                    mode="multiple"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" @click="$refs.showInTable.refresh(true)">查询</a-button>
                  <a-button type="query" @click="showBothOuterStatics">查询两头在外统计</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="showInTable"
          :rowKey="function(record) { return getRandomCode(8) }"
          size="small"
          :alert="{
            show: true,
            msg: showInTotal
          }"
          :scroll="{ x: 'max-content' }"
          :columns="showInColumns"
          :data="loadShowInData"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
        <a-modal
          :visible="bothOuterStaticsVisible"
          :confirmLoading="bothOuterStaticsLoading"
          :width="1200"
          :maskClosable="false"
          :footer="null"
          title="两头在外统计"
          @cancel="bothOuterStaticsVisible = false">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col :span="6">
                <a-form-item label="订单类型">
                  <a-select v-model="bothOuterQueryParam.bill_type"
                    @change="queryBothOuterStatics"
                    placeholder="请选择订单类型" allowClear
                    style="width: 150px">
                    <a-select-option :value="1">出货</a-select-option>
                    <a-select-option :value="2">代销</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" >
                <a-form-item label="销售时间">
                  <a-range-picker :default-value="[bothOuterQueryParam.start_date,bothOuterQueryParam.end_date]" @change="(a, b) => onDateChange('bothouter', a, b)"/>
                </a-form-item>
              </a-col>
              <a-col :span="6" >
                <span class="table-page-search-submitButtons">
                  <a-button type="query" @click="exportBothOutDetail">导出明细</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
          <a-table
            size="small"
            :rowKey="() => { return 'bothouter'+getRandomCode(8) }"
            :scroll="{ x: 'max-content' }"
            :columns="bothOuterColumns"
            :data-source="bothOuterData"
            :pagination="false"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </a-table>
        </a-modal>
      </a-tab-pane>
      <a-tab-pane tab="商品出货明细表" key="2">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="出库单号">
                  <a-input v-model.trim="queryOutParam['sell_num']" placeholder="根据出库单号进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="出库时间">
                  <a-range-picker @change="(a, b) => onDateChange('out', a, b)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择货名"
                    style="width: 100%; z-index: 2;"
                    :value="goodInfo2"
                    :filter-option="false"
                    :not-found-content="fetching2 ? undefined : null"
                    @search="getGoodInfo2"
                    @change="changeGoodInfo2"
                    mode="multiple"
                  >
                    <template v-if="fetching2" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in goodOps2" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="购买单位">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择购买单位"
                    style="width: 100%"
                    :value="outCustomerInfo"
                    :filter-option="false"
                    :not-found-content="outFetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                    mode="multiple"
                  >
                    <template v-if="outFetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="供应商">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    placeholder="选择供应商"
                    v-model="queryOutParam['supplier_ids']"
                    mode="multiple"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订单类型">
                  <a-select
                    style="width: 100%"
                    v-model="queryOutParam['order_types']"
                    allowClear
                    placeholder="选择订单类型"
                    mode="multiple"
                  >
                    <a-select-option :value="1">普通</a-select-option>
                    <a-select-option :value="2">团购</a-select-option>
                    <a-select-option :value="3">工会</a-select-option>
                    <a-select-option :value="4">内购</a-select-option>
                    <a-select-option :value="5">饭卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订购人">
                  <a-input v-model.trim="queryOutParam['order_person']" placeholder="根据订购人进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订购电话">
                  <a-input v-model.trim="queryOutParam['order_phone']" placeholder="根据订购电话进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品种类">
                  <a-select
                    mode="multiple"
                    placeholder="请选择货物种类"
                    style="width: 100%"
                    v-model="queryOutParam['good_type_ids']"
                    allowClear
                  >
                    <a-select-option v-for="op in goodTypeData" :key="op.id">{{ op.good_type }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-operator">
          <a-button type="query" icon="search" @click="$refs.showOutTable.refresh(true)">查询</a-button>
          <a-popover
            v-model="popVisible2"
            title="选择年份"
            trigger="click">
            <a slot="content">
              <p><a-date-picker
                v-model="exportDate2"
                mode="year"
                style="width: 200px;"
                format="YYYY"
                placeholder="请选择年份"
                :open="yearShowOne2"
                @openChange="openChangeOne2"
                @panelChange="panelChangeOne2">
              </a-date-picker></p>
              <p style="text-align: right"><a-button type="primary" @click="exportSalesSheet2">确定</a-button></p>
            </a>
            <a-button type="primary" icon="download">销售清单(出货)</a-button>
          </a-popover>
          <a-popover
            v-model="popVisible3"
            title="选择年份"
            trigger="click">
            <a slot="content">
              <p>
                <a-date-picker
                  v-model="exportDate3"
                  mode="year"
                  style="width: 200px;"
                  format="YYYY"
                  placeholder="请选择年份"
                  :open="yearShowOne3"
                  @openChange="openChangeOne3"
                  @panelChange="panelChangeOne3">
                </a-date-picker>
              </p>
              <p>
                <a-form-model-item label="商品是否合并">
                  <a-select
                    v-model="isMerge"
                    style="width: 200px;"
                    placeholder="是否合并">
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>
                </a-form-model-item>
              </p>
              <p style="text-align: right"><a-button type="primary" @click="exportAllSalesSheet">确定</a-button></p>
            </a>
            <a-button type="primary" icon="download">销售清单(全部)</a-button>
          </a-popover>
          <a-button type="primary" icon="download" @click="exportAllSalesStatisticsSheet">销售统计表(全部)</a-button>
          <a-button type="primary" icon="download" @click="exportMarketingAnalysisSheet">订购人订购信息表(全部)</a-button>
          <a-button type="primary" icon="download" @click="exportSupplierAnalysisSheet">供应商销售统计表</a-button>
          <a-button type="primary" icon="download" @click="exportSaleGoodsSheet">商品销售统计表(全部)</a-button>
        </div>
        <s-table
          ref="showOutTable"
          size="small"
          :rowKey="function(record) { return getRandomCode(8) }"
          :alert="{
            show: true,
            msg: showOutTotal
          }"
          :scroll="{ x: 'max-content' }"
          :columns="showOutColumns"
          :data="loadShowOutData"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="商品代销明细表" key="3">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="代销单号">
                  <a-input v-model.trim="queryAgentParam['agent_num']" placeholder="根据代销单号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="代销时间">
                  <a-range-picker @change="(a, b) => onDateChange('agent', a, b)"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品名称">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择货名"
                    style="width: 100%; z-index: 2;"
                    :value="goodInfo3"
                    :filter-option="false"
                    :not-found-content="fetching3 ? undefined : null"
                    @search="getGoodInfo3"
                    @change="changeGoodInfo3"
                    mode="multiple"
                  >
                    <template v-if="fetching3" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in goodOps3" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="购买单位">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择购买单位"
                    style="width: 100%"
                    :value="agentCustomerInfo"
                    :filter-option="false"
                    :not-found-content="agentFetching ? undefined : null"
                    @search="agentGetDepartmentCustomer"
                    @change="agentChangeCustomerInfo"
                    mode="multiple"
                  >
                    <template v-if="agentFetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in agentBuyerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="供应商">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    placeholder="选择供应商"
                    v-model="queryAgentParam['supplier_ids']"
                    mode="multiple"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订单类型">
                  <a-select
                    style="width: 100%"
                    v-model="queryAgentParam['order_types']"
                    allowClear
                    placeholder="选择订单类型"
                    mode="multiple"
                  >
                    <a-select-option :value="1">普通</a-select-option>
                    <a-select-option :value="2">团购</a-select-option>
                    <a-select-option :value="3">工会</a-select-option>
                    <a-select-option :value="4">内购</a-select-option>
                    <a-select-option :value="5">饭卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订购人">
                  <a-input v-model.trim="queryAgentParam['order_person']" placeholder="根据订购人进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订购电话">
                  <a-input v-model.trim="queryAgentParam['order_phone']" placeholder="根据订购电话进行搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="商品种类">
                  <a-select
                    mode="multiple"
                    placeholder="请选择货物种类"
                    style="width: 100%"
                    v-model="queryAgentParam['good_type_ids']"
                    allowClear
                  >
                    <a-select-option v-for="op in goodTypeData" :key="op.id">{{ op.good_type }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-operator">
          <a-button type="query" icon="search" @click="$refs.showAgentTable.refresh(true)">查询</a-button>
          <a-popover
            v-model="popVisible"
            title="选择年份"
            trigger="click">
            <a slot="content">
              <p><a-date-picker
                v-model="exportDate"
                mode="year"
                style="width: 200px;"
                format="YYYY"
                placeholder="请选择年份"
                :open="yearShowOne"
                @openChange="openChangeOne"
                @panelChange="panelChangeOne">
              </a-date-picker></p>
              <p style="text-align: right"><a-button type="primary" @click="exportSalesSheet">确定</a-button></p>
            </a>
            <a-button type="primary" icon="download">销售清单(代销)</a-button>
          </a-popover>
        </div>
        <s-table
          ref="showAgentTable"
          size="small"
          :rowKey="function(record) { return getRandomCode(8) }"
          :alert="{
            show: true,
            msg: showAgentTotal
          }"
          :scroll="{ x: 'max-content' }"
          :columns="showAgentColumns"
          :data="loadShowAgentData">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="出货快递统计表" key="4">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="类型">
                  <a-select
                    v-model="queryParam4['type']"
                    style="width: 100%"
                    @change="loadStaticsSellCourierData"
                  >
                    <a-select-option v-for="op in typeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="时间">
                  <a-range-picker
                    :defaultValue="[queryParam4['start_date'], queryParam4['end_date']]"
                    @change="(date, dateStr) => onDateChange('courier', date, dateStr)"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-operator">
          <a-button type="query" icon="search" @click="loadStaticsSellCourierData">查询</a-button>
          <a-button type="primary" icon="download" @click="exportStaticsSellCourierSheet">导出</a-button>
        </div>
        <a-table
          size="small"
          :rowKey="() => { return getRandomCode(8) }"
          :scroll="{ x: 'max-content' }"
          :columns="courierColumns"
          :data-source="courierData"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getFirstLetter, getRandomCode } from '@/utils/util'
import {
  getStaticsShowInDetail,
  getStaticsShowOutDetail,
  getStaticsShowAgentDetail,
  getGoodInfo,
  exportAgentSalesSheet,
  exportSellSalesSheet,
  exportAllSalesSheet,
  exportAllSalesStatisticsSheet,
  exportMarketingAnalysisSheet,
  exportSupplierAnalysisSheet,
  staticsSellCourier,
  exportStaticsSellCourier,
  exportStatisticsSaleGoodsTotalSheet,
  getGoodType,
  getBothOuterStatics,
  handleExportBothOuterDetail
} from '@/api/wms/sale'
import { getCustomer, getSupplier, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce'
import moment from 'moment'

export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.agentLastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.lastFetchId2 = 0;
    this.getGoodInfo = debounce(this.getGoodInfo, 800);
    this.lastFetchId3 = 0;
    this.getGoodInfo2 = debounce(this.getGoodInfo2, 800);
    this.lastFetchId4 = 0;
    this.getGoodInfo3 = debounce(this.getGoodInfo3, 800);
    return {
      queryInParam: {}, // 入库明细查询条件
      queryOutParam: {}, // 出库明细查询条件
      queryAgentParam: {}, // 代销明细查询条件
      queryParam4: { // 出货快递统计表查询条件
        type: 1,
        start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      bothOuterQueryParam: {
        bill_type: null,
        start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      bothOuterStaticsVisible: false,
      bothOuterStaticsLoading: false,
      goodInfo: undefined,
      fetching: false,
      goodOps: [], // 入库商品信息
      goodInfo2: undefined,
      fetching2: false,
      goodOps2: [], // 出库商品信息
      goodInfo3: undefined,
      fetching3: false,
      goodOps3: [], // 代销商品信息
      agentBuyerOps: [],
      agentCustomerInfo: undefined,
      agentFetching: false,
      buyerOps: [],
      outCustomerInfo: undefined,
      outFetching: false,
      showInTotal: '', // 商品进货明细统计信息
      showOutTotal: '', // 商品出货明细统计信息
      showAgentTotal: '', // 商品代销明细统计信息
      supplierOps: [], // 供应商
      showInColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num'
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date'
        },
        {
          title: '仓库',
          dataIndex: 'warehouse'
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name'
        },
        {
          title: '商品编码',
          dataIndex: 'good_no'
        },
        {
          title: '商品名称',
          dataIndex: 'good_name'
        },
        {
          title: '单位',
          dataIndex: 'good_unit'
        },
        {
          title: '规格',
          dataIndex: 'good_spec'
        },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '单价',
          dataIndex: 'unit_price'
        },
        {
          title: '总价',
          dataIndex: 'money'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '录入人',
          dataIndex: 'creator'
        },
        {
          title: '录入时间',
          dataIndex: 'created_at'
        }
      ],
      showOutColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '出货单号',
          dataIndex: 'sell_num'
        },
        {
          title: '出货时间',
          dataIndex: 'out_date'
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name'
        },
        {
          title: '订购人',
          dataIndex: 'order_person'
        },
        {
          title: '订购电话',
          dataIndex: 'order_phone'
        },
        {
          title: '订单类型',
          dataIndex: 'order_type',
          customRender: (value) => {
            if (value) {
              switch (value) {
                case 1:
                  return '普通';
                case 2:
                  return '团购';
                case 3:
                  return '工会';
                case 4:
                  return '内购';
                case 5:
                  return '饭卡';
              }
            }
          }
        },
        {
          title: '编码',
          dataIndex: 'good_no'
        },
        {
          title: '品名',
          dataIndex: 'good_name'
        },
        {
          title: '单位',
          dataIndex: 'good_unit'
        },
        {
          title: '规格',
          dataIndex: 'good_spec'
        },
        {
          title: '单价',
          dataIndex: 'price_out'
        },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '总价',
          dataIndex: 'money'
        },
        {
          title: '录入人',
          dataIndex: 'creator'
        },
        {
          title: '录入时间',
          dataIndex: 'created_at'
        }
      ],
      showAgentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '代销单号',
          dataIndex: 'agent_num'
        },
        {
          title: '代销时间',
          dataIndex: 'agent_date'
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name'
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name'
        },
        {
          title: '订购人',
          dataIndex: 'order_person'
        },
        {
          title: '订购电话',
          dataIndex: 'order_phone'
        },
        {
          title: '订单类型',
          dataIndex: 'order_type',
          customRender: (value) => {
            if (value) {
              switch (value) {
                case 1:
                  return '普通';
                case 2:
                  return '团购';
                case 3:
                  return '工会';
                case 4:
                  return '内购';
                case 5:
                  return '饭卡';
              }
            }
          }
        },
        {
          title: '编码',
          dataIndex: 'good_no'
        },
        {
          title: '品名',
          dataIndex: 'good_name'
        },
        {
          title: '单位',
          dataIndex: 'good_unit'
        },
        {
          title: '规格',
          dataIndex: 'good_spec'
        },
        {
          title: '单价',
          dataIndex: 'price_out'
        },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '总价',
          dataIndex: 'money'
        },
        {
          title: '录入人',
          dataIndex: 'creator'
        },
        {
          title: '录入时间',
          dataIndex: 'created_at'
        }
      ],
      loadShowInData: param => {
        return getStaticsShowInDetail(Object.assign(param, this.queryInParam)).then(res => {
          if (res.total_row) {
            this.showInTotal = '数量：' + res.total_row.total + '; 金额：' + res.total_row.money;
          }
          return res;
        });
      },
      loadShowOutData: param => {
        return getStaticsShowOutDetail(Object.assign(param, this.queryOutParam)).then(res => {
          if (res.total_row) {
            this.showOutTotal = '数量：' + res.total_row.total + '; 金额：' + res.total_row.money;
          }
          return res;
        });
      },
      loadShowAgentData: param => {
        return getStaticsShowAgentDetail(Object.assign(param, this.queryAgentParam)).then(res => {
          if (res.total_row) {
            this.showAgentTotal = '数量：' + res.total_row.total + '; 金额：' + res.total_row.money;
          }
          return res;
        });
      },
      popVisible: false,
      exportDate: null,
      yearShowOne: false,
      popVisible2: false,
      exportDate2: null,
      yearShowOne2: false,
      popVisible3: false,
      exportDate3: null,
      exportStartDate3: null,
      exportEndDate3: null,
      yearShowOne3: false,
      // 导出销售清单(全部)是否合并、0否 1是 、默认不合并
      isMerge: 0,
      typeOps: [
        { value: 1, name: '月' },
        { value: 2, name: '年' }
      ],
      courierColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '日期',
          dataIndex: 'date'
        },
        {
          title: '快递票数',
          dataIndex: 'courier_count'
        },
        {
          title: '快递包裹数',
          dataIndex: 'package_num'
        },
        {
          title: '送货上门票数',
          dataIndex: 'num'
        }
      ],
      courierData: [],
      goodTypeData: [],
      bothOuterColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '收入(去税)',
          dataIndex: 'total_price_out_tax'
        },
        {
          title: '成本(去税)',
          dataIndex: 'total_price_in_tax'
        },
        {
          title: '利润(去税)',
          dataIndex: 'total_profit'
        }
      ],
      bothOuterData: []
    };
  },
  created() {
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
    this.loadGoodType()
  },
  methods: {
    getRandomCode,
    loadGoodType() {
      getGoodType().then(res => {
        this.goodTypeData = res
      })
    },
    tabChange(key) {
      if (key === '4') {
        // 查询出货快递统计表
        this.loadStaticsSellCourierData()
      }
    },
    loadStaticsSellCourierData() {
      staticsSellCourier(this.queryParam4).then(res => {
        this.courierData = res
      })
    },
    exportStaticsSellCourierSheet() {
      this.$confirm({
        title: '提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportStaticsSellCourier(this.queryParam4).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    // 营销分析表（全部）
    exportMarketingAnalysisSheet() {
      exportMarketingAnalysisSheet(this.queryOutParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    // 供应商销售统计
    exportSupplierAnalysisSheet() {
      exportSupplierAnalysisSheet(this.queryOutParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    exportSaleGoodsSheet() {
      exportStatisticsSaleGoodsTotalSheet(this.queryOutParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    // 销售统计表(全部)
    exportAllSalesStatisticsSheet() {
      exportAllSalesStatisticsSheet(this.queryOutParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    // 销售清单(全部)
    exportAllSalesSheet() {
      if (this.exportDate3) {
        this.queryOutParam['year'] = this.exportDate3.year();
        this.queryOutParam['is_merge'] = this.isMerge
        exportAllSalesSheet(this.queryOutParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible3 = false;
        });
      } else {
        this.$message.warning('请选择导出年份');
      }
    },
    openChangeOne3(status) {
      if (status) {
        this.yearShowOne3 = true;
      }
    },
    panelChangeOne3(value) {
      this.exportDate3 = value;
      this.yearShowOne3 = false;
    },
    onDateChangeOne3(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.exportStartDate3 = startDate
        this.exportEndDate3 = endDate
      } else {
        this.exportStartDate3 = null
        this.exportEndDate3 = null
      }
    },
    // 销售清单(出货)
    exportSalesSheet2() {
      if (this.exportDate2) {
        this.queryOutParam['year'] = this.exportDate2.year();
        exportSellSalesSheet(this.queryOutParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible2 = false;
        });
      } else {
        this.$message.warning('请选择导出年份');
      }
    },
    openChangeOne2(status) {
      if (status) {
        this.yearShowOne2 = true;
      }
    },
    panelChangeOne2(value) {
      this.exportDate2 = value;
      this.yearShowOne2 = false;
    },
    // 销售清单(代销)
    exportSalesSheet() {
      if (this.exportDate) {
        this.queryAgentParam['year'] = this.exportDate.year();
        exportAgentSalesSheet(this.queryAgentParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        });
      } else {
        this.$message.warning('请选择导出年份');
      }
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    panelChangeOne(value) {
      this.exportDate = value;
      this.yearShowOne = false;
    },
    getGoodInfo3(value) {
      this.lastFetchId4 += 1;
      const fetchId = this.lastFetchId4;
      this.goodOps3 = [];
      this.fetching3 = true;
      getGoodInfo({ goodName: value }).then(res => {
        if (fetchId !== this.lastFetchId4) {
          return;
        }
        this.goodOps3 = res;
        this.fetching3 = false;
      });
    },
    changeGoodInfo3(value) {
      this.goodInfo3 = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(v => ids.push(v.key));
      }
      this.queryAgentParam['good_ids'] = ids;
      this.goodOps3 = [];
      this.fetching3 = false;
    },
    getGoodInfo2(value) {
      this.lastFetchId3 += 1;
      const fetchId = this.lastFetchId3;
      this.goodOps2 = [];
      this.fetching2 = true;
      getGoodInfo({ goodName: value }).then(res => {
        if (fetchId !== this.lastFetchId3) {
          return;
        }
        this.goodOps2 = res;
        this.fetching2 = false;
      });
    },
    changeGoodInfo2(value) {
      this.goodInfo2 = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(v => ids.push(v.key));
      }
      this.queryOutParam['good_ids'] = ids;
      this.goodOps2 = [];
      this.fetching2 = false;
    },
    getGoodInfo(value) {
      this.lastFetchId2 += 1;
      const fetchId = this.lastFetchId2;
      this.goodOps = [];
      this.fetching = true;
      getGoodInfo({ goodName: value }).then(res => {
        if (fetchId !== this.lastFetchId2) {
          return;
        }
        this.goodOps = res;
        this.fetching = false;
      });
    },
    changeGoodInfo(value) {
      this.goodInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(v => ids.push(v.key));
      }
      this.queryInParam['good_ids'] = ids;
      this.goodOps = [];
      this.fetching = false;
    },
    onDateChange(flag, date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (flag === 'in') {
          this.queryInParam['purchase_start_date'] = startDate;
          this.queryInParam['purchase_end_date'] = endDate;
        } else if (flag === 'out') {
          this.queryOutParam['start_date'] = startDate;
          this.queryOutParam['end_date'] = endDate;
        } else if (flag === 'agent') {
          this.queryAgentParam['start_date'] = startDate;
          this.queryAgentParam['end_date'] = endDate;
        } else if (flag === 'courier') {
          this.queryParam4['start_date'] = startDate
          this.queryParam4['end_date'] = endDate
        } else if (flag === 'bothouter') {
          this.bothOuterQueryParam['start_date'] = startDate
          this.bothOuterQueryParam['end_date'] = endDate
          this.queryBothOuterStatics();
        }
      } else {
        if (flag === 'in') {
          this.queryInParam['purchase_start_date'] = null;
          this.queryInParam['purchase_end_date'] = null;
        } else if (flag === 'out') {
          this.queryOutParam['start_date'] = null;
          this.queryOutParam['end_date'] = null;
        } else if (flag === 'agent') {
          this.queryAgentParam['start_date'] = null;
          this.queryAgentParam['end_date'] = null;
        } else if (flag === 'courier') {
          this.queryParam4['start_date'] = null
          this.queryParam4['end_date'] = null
        } else if (flag === 'bothouter') {
          this.bothOuterQueryParam['start_date'] = null
          this.bothOuterQueryParam['end_date'] = null
        }
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.outFetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res;
        this.outFetching = false;
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    agentGetDepartmentCustomer(value) {
      this.agentLastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agentBuyerOps = [];
      this.agentFetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agentBuyerOps = res;
        this.agentFetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.outCustomerInfo = value
        const ids = []
        value.forEach(item => {
          ids.push(item.key)
        })
        this.queryOutParam['buyer_ids'] = ids
        this.buyerOps = [];
        this.outFetching = false;
      } else {
        this.outCustomerInfo = value
        this.queryOutParam['buyer_ids'] = []
        this.buyerOps = [];
        this.outFetching = false;
      }
    },
    agentChangeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.agentCustomerInfo = value
        const ids = []
        value.forEach(item => {
          ids.push(item.key)
        })
        this.queryAgentParam['buyer_ids'] = ids
        this.agentBuyerOps = [];
        this.agentFetching = false;
      } else {
        this.agentCustomerInfo = value
        this.queryAgentParam['buyer_ids'] = ''
        this.agentBuyerOps = [];
        this.agentFetching = false;
      }
    },
    showBothOuterStatics() {
      this.bothOuterStaticsVisible = true
      this.queryBothOuterStatics()
    },
    queryBothOuterStatics() {
      getBothOuterStatics(this.bothOuterQueryParam).then(res => {
        this.bothOuterData = res
      })
    },
    exportBothOutDetail() {
      handleExportBothOuterDetail(this.bothOuterQueryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        });
    }
  }
}
</script>
